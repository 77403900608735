export function getComponentFiles(options) {
  const {
    components = [],
    componentName = "",
    componentIdentifier = "",
    numToSelect,
    selectionOffset = 0,
  } = options;

  const selectedComponents = components.filter(
    ({ fastId, componentIdentifier: thisComponentIdentifier = "" }) => {
      if (!componentName) {
        return componentIdentifier === thisComponentIdentifier;
      }
      if (!componentIdentifier) {
        return fastId === componentName;
      }
      return (
        fastId === componentName &&
        componentIdentifier === thisComponentIdentifier
      );
    }
  );

  if (!selectedComponents.length) {
    return null;
  }

  const selectedComponent = selectedComponents[0];

  if (!selectedComponent.files.length) {
    return null;
  }

  const files = selectedComponent.files.map(
    ({ file, gatsbyFile, altText, _id }) => {
      if (gatsbyFile) {
        return { ...gatsbyFile.childImageSharp, altText, _id };
      }
      return file;
    }
  );

  if (!numToSelect) {
    return files;
  }

  if (numToSelect === 1) {
    return files[selectionOffset];
  }

  return files.slice(selectionOffset, selectionOffset + numToSelect + 1);
}

export function getPageHeroImage({
  options,
  numToSelect,
  selectionOffset = 0,
}) {
  const heroElementComponents = options.filter(
    ({ fastId }) => fastId === "HeroElement"
  );

  const imageOptions =
    heroElementComponents.length > 0 &&
    heroElementComponents[0].files.length > 0
      ? heroElementComponents[0].files.map(({ file, gatsbyFile, altText }) => {
          if (gatsbyFile) {
            return { ...gatsbyFile.childImageSharp, altText };
          }
          return file;
        })
      : [];

  if (!numToSelect) {
    return imageOptions;
  }

  if (numToSelect === 1) {
    return imageOptions[selectionOffset];
  }

  return imageOptions.slice(selectionOffset, selectionOffset + numToSelect + 1);
}

export function getPageGalleryImages({
  options,
  numToSelect,
  selectionOffset = 0,
}) {
  const imageCollectionComponents = options.filter(
    ({ fastId }) => fastId === "ImageCollection"
  );

  const imageOptions =
    imageCollectionComponents.length > 0
      ? imageCollectionComponents[0].files.map(
          ({ file, gatsbyFile, altText }) => {
            if (gatsbyFile) {
              return { ...gatsbyFile.childImageSharp, altText };
            }
            return file;
          }
        )
      : [];

  if (!numToSelect) {
    return imageOptions;
  }

  if (numToSelect === 1) {
    return imageOptions[selectionOffset];
  }

  return imageOptions.slice(selectionOffset, selectionOffset + numToSelect + 1);
}

export function filterRegularLinks({ links }) {
  return links.filter(({ props }) => {
    return JSON.parse(props).as !== "Button";
  });
}

export function filterButtonLinks({ links }) {
  return links.filter(({ props }) => {
    return JSON.parse(props).as === "Button";
  });
}

export function getComponentContentNodeContent({
  components,
  componentName,
  componentId,
  defaultValue = "",
  selectFirst = true,
}) {
  if (!componentId || !components || !components.length) {
    return defaultValue;
  }

  const foundComponents = components.filter(
    ({ fastId, componentIdentifier }) => {
      if (!componentName) {
        return componentIdentifier === componentId;
      }
      return fastId === componentName && componentIdentifier === componentId;
    }
  );

  if (foundComponents.length === 0) {
    return defaultValue;
  }
  if (!selectFirst && foundComponents.length > 1) {
    return defaultValue;
  }

  const foundComponent = foundComponents[0];
  const { contentNodes = [] } = foundComponent;

  if (contentNodes.length === 0) {
    return defaultValue;
  }
  if (!selectFirst && contentNodes.length > 1) {
    return defaultValue;
  }

  const contentNode = contentNodes[0];
  const { content = "" } = contentNode;

  return content;
}

export function withArgs({ func, args = [] }) {
  return (...funcArgs) => func(...funcArgs, ...args);
}
